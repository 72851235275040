<script setup lang="ts">
const emit = defineEmits<{
  click: [string];
}>();
function handleClick(provider: string) {
  emit("click", provider);
}
</script>

<template>
  <ul class="gap-4 flex flex-col stretch">
    <li class="w-full stretch">
      <a class="block" href="/auth/google" @click="handleClick('google')">
        <Button
          size="lg"
          variant="outline"
          class="block w-full !py-7 border-slate-300 text-slate-700"
        >
          <Icon size="1.5em" class="mr-4 -ml-4" name="devicon:google" />
          <span class="block mr-auto">Continue with Google</span>
        </Button>
      </a>
    </li>
    <li class="w-full stretch">
      <a class="block" href="/auth/microsoft" @click="handleClick('microsoft')">
        <Button
          size="lg"
          variant="outline"
          class="block w-full !py-7 border-slate-300 text-slate-700"
        >
          <Icon size="1.5em" class="mr-4 -ml-4" name="logos:microsoft-icon" />
          <span class="block mr-auto">Continue with Microsoft</span>
        </Button>
      </a>
    </li>
    <li class="w-full stretch">
      <a class="block" href="/auth/x" @click="handleClick('x')">
        <Button
          size="lg"
          variant="outline"
          class="block w-full !py-7 border-slate-300 text-slate-700"
        >
          <Icon size="1.5em" class="mr-4 -ml-4" name="devicon:twitter" />
          <span class="block mr-auto">Continue with X</span>
        </Button>
      </a>
    </li>
    <li class="w-full stretch">
      <a class="block" href="/auth/facebook" @click="handleClick('facebook')">
        <Button
          size="lg"
          variant="outline"
          class="block w-full !py-7 border-slate-300 text-slate-700"
        >
          <Icon size="1.5em" class="mr-4 -ml-4" name="devicon:facebook" />
          <span class="block mr-auto">Continue with Facebook</span>
        </Button>
      </a>
    </li>
  </ul>
</template>
